<template>
  <div>
    <div v-if="loaded = true" class="container customers pb-16 fadeInFast">
      <Message
          :message="message"
          @closeAlert="message.show = false"
      ></Message>
      <v-row>
        <v-col cols="12" md="6" lg="5">
          <HeadlineDetailView
              :icon="'mdi-account'"
              :headline="customer.firstname +' '+  customer.lastname"
              :entity="'Kunde'"
          >
          </HeadlineDetailView>
        </v-col>
        <v-col cols="12" md="6" lg="7" class="text-end">
          <a class="v-btn" @click="unArchiveWedding()" v-if="customer.isArchived">
            <v-btn class=" mt-2 ms-3 " width="170">
              <v-icon class="pe-2 ">
                mdi mdi-star
              </v-icon>
              Aus Archiv
            </v-btn>
          </a>
          <a class="v-btn" @click="archiveCustomer()" v-else>
            <v-btn class=" mt-2 ms-3 " width="170">
              <v-icon class="pe-2 ">
                mdi mdi-archive-outline
              </v-icon>
              Archivieren
            </v-btn>
          </a>
          <a class="v-btn" :href="'tel:'+customer.tel" v-if="!customer.isArchived">
            <v-btn class="primary ms-3 mt-2" width="150">
              <v-icon class="pe-2">
                mdi-phone
              </v-icon>
              Anrufen
            </v-btn>
          </a>
          <a class="v-btn" :href="'mailto:'+customer.email" v-if="!customer.isArchived">
            <v-btn class="primary  ms-3 mt-2" width="150">
              <v-icon class="pe-2">
                mdi-mail
              </v-icon>
              E-Mail
            </v-btn>
          </a>
        </v-col>
      </v-row>
      <v-card flat class="detailCard pa-4 rounded-xl mt-6 pb-8">
        <Tab
            :tabs="['Allgemein','Partner','Adresse','Rechnungen','Sonstiges']"
            :register="tabs"
            @changed="tabs=$event"
        >
        </Tab>

        <div class="row pa-4" v-if="tabs === 0">
          <div class="col-12 col-sm-7">
            <KeyValueBlock
                :title="''"
                :key-values="[
              {title: 'Vorname', value: customer.firstname, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'customer',
                id: customer.id,
                row: 'firstname'
              }},
               {title: 'Nachname', value: customer.lastname, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'customer',
                id: customer.id,
                row:'lastname'
              }},
              {
                title: 'Status',
                value: customer.status,
                editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'customer',
                  id: customer.id,
                  row: 'status',
                  type: 'autoComplete',
                  autoComplete:{
                    value: customer.status,
                    color: getCustomerStatusColor(customer.status),
                    items: customerStatusForSelection
                  }
                }
              },
               {title: 'Tel', value: customer.tel, isEdit: false,
               editBtn:{
                icon:'mdi-check-circle',
                entity: 'customer',
                id: customer.id,
                row: 'tel'
              }},
              {title: 'E-Mail', value: customer.email, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'customer',
                id: customer.id,
                row: 'email'
              }},
              {
                title: 'Notizen',
                value: customer.notes,
                isEdit: false,
                editBtn:{
                  icon:'mdi-check-circle',
                  entity: 'customer',
                  id: customer.id,
                  row: 'notes',
                  type: 'textarea'
                }
              },
              ]"
                @editEntry="editEntry"
            >
            </KeyValueBlock>
            <CustomFieldBlock :entity="'customer'" :user="user" :entryId="customer.id"/>

          </div>
          <div class="col-12 col-sm-5">
            <div v-if="weddingsForCustomer.length > 0" class="">
              <v-row>

                <v-col cols="12" v-for="wedding of weddingsForCustomer" :key="wedding.id">
                  <v-card class="rounded-xl mb-3"
                          style="background-color: #F3F4F3" @click="openWedding(wedding.id)" flat>
                    <v-row>
                      <v-col cols="12" class="">
                        <v-card-title>
                          <h5 class="" style="width: 100%">
                            <v-icon class="pe-1">mdi-ring</v-icon>
                            {{ customer.firstname }}<span v-if="customer.partnerFirstname"> und {{
                              customer.partnerFirstname
                            }}</span> <small><span v-if="wedding.isArchived"
                                                   class="red--text"><i>Archiviert</i></span></small>
                          </h5>
                        </v-card-title>
                        <v-card-text>

                          <b>{{ wedding.values.status }}</b><br>
                          Am
                          <b>{{ getStringDate(wedding.wedDate) }}</b>
                        </v-card-text>
                        <v-card-actions class="">
                          <v-spacer></v-spacer>
                          <a>ANSEHEN</a>
                        </v-card-actions>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>

              </v-row>



            </div>
            <div v-else class="pa-4 pt-8">
              <p><i>Es gibt noch kein Hochzeit zu diesem Kunden</i></p>
              <v-btn @click="dialogCreateWedding = true" class="primary">
                Hochzeit erstellen
              </v-btn>
            </div>
            <v-row  v-for="msg of inbox" :key="msg.subject+msg.date" class="customerRow ">
              <v-col cols="3" class="overflow-hidden pe-8">
                <span style="white-space: nowrap;" >  {{msg.from[0]}}</span>
              </v-col>
              <v-col cols="" class="overflow-hidden ps-8 pe-8">
                <span style="white-space: nowrap" class="overflow-hidden">{{msg.subject[0]}}</span>
              </v-col>
              <v-col cols="" class="overflow-hidden ps-8">
                <span style="white-space: nowrap;">{{getStringDate(msg.date)}}</span>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="row pa-4" v-if="tabs === 1">
          <div class="col-12 col-sm-7">
            <KeyValueBlock
                :key-values="
                [
                  {
                    title: 'Vorname', value: customer.partnerFirstname, isEdit: false,
                    editBtn:
                  {
                    icon:'mdi-check-circle',
                    entity: 'customer',
                    id: customer.id,
                    row: 'partnerFirstname',
                  }
                  },
                  {
                    title: 'Nachname', value: customer.partnerLastname, isEdit: false,
                    editBtn:
                    {
                      icon:'mdi-check-circle',
                      entity: 'customer',
                      id: customer.id,
                      row: 'partnerLastname'
                    }
                  },
                   {title: 'Telefon', value: customer.partnerTel, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'customer',
                id: customer.id,
                row: 'partnerTel'
              }},
               {title: 'E-Mail', value: customer.partnerMail, isEdit: false, editBtn:{
                icon:'mdi-check-circle',
                entity: 'customer',
                id: customer.id,
                row: 'partnerMail'
              }}
                ]" @editEntry="editEntry">
            </KeyValueBlock>
          </div>
          <div class="col-12 col-sm-5">
          </div>
        </div>
        <div class="row pa-4" v-if="tabs === 2">
          <div class="col-12 col-sm-7">
            <KeyValueBlock
                :key-values="[
                  {
                    title: 'Straße',
                    value: customer.street,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'customers',
                      id: customer.id,
                      row: 'street'
                    }
                  },
                  {
                    title: 'Hausnummer',
                    value: customer.houseNumber,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'customer',
                      id: customer.id,
                      row: 'houseNumber'
                    }
                  },
                  {
                    title: 'Postleitzahl',
                    value: customer.plz,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'customer',
                      id: customer.id,
                      row: 'plz'
                    }
                  },
                  {
                    title: 'Ort',
                    value: customer.city,
                    isEdit: false,
                    editBtn:{
                      icon:'mdi-check-circle',
                      entity: 'customer',
                      id: customer.id,
                      row: 'city'
                    }
                  },
              ]"
                @editEntry="editEntry">
            </KeyValueBlock>
          </div>
          <div class="col-12 col-sm-5">
            <div v-if="customer.street !== '' && customer.street !== undefined && customer.street !== null">
              <p>
                {{ customer.street }} {{ customer.houseNumber }}<br>
                {{ customer.plz }} {{ customer.city }}
              </p>
            </div>
          </div>
        </div>
        <div v-if="tabs === 3">
          <v-container>
            <v-row>
              <v-col cols="12" >
                <v-btn @click="dialogInvoice =true" class="primary">
                  Neue Rechnung
                </v-btn>
                <VirtualScrollInvoice
                    :items="invoices"
                    :noResultsText="'Du hast aktuell keine offenen Rechnungen für diese Hochzeit.'"
                >
                </VirtualScrollInvoice>
              </v-col>
            </v-row>
          </v-container>

        </div>
        <div class="row pa-4" v-if="tabs === 4">
          <div class="col-12 col-sm-7">
            <KeyValueBlock
                :key-values="[
              {title: 'Erstellt am', value: getStringDate(customer.created), isEdit: false},
              ]">
            </KeyValueBlock>
          </div>
          <div class="col-12 col-sm-5">
          </div>
        </div>
      </v-card>
    </div>
    <CreateInvoice
        :dialog="dialogInvoice"
        :customerSelection="customer.id"
        @hideDialog="dialogInvoice = false"
    ></CreateInvoice>
    <CreateWedding
        :dialog="dialogCreateWedding"
        :openAfterCreate="true"
        :customerSelection="customer.id"
        @hideDialog="hideDialog"
    ></CreateWedding>
  </div>
</template>
<script>

import {mapGetters} from "vuex"
import {date2String, error, msgObj} from "@/helper/helper";

const Message = () => import("@/components/generalUI/Message");
const KeyValueBlock = () => import("@/components/detailView/KeyValueBlock");
const HeadlineDetailView = () => import("@/components/detailView/HeadlineDetailView");
const CustomFieldBlock = () => import("@/components/detailView/CustomFieldBlock");
const CreateWedding = () => import("@/components/dialog/createDialogs/CreateWedding");
const Tab = () => import("@/components/generalUI/Tab");
const VirtualScrollInvoice = () => import("@/components/entities/wedding/VirtualScrollInvoice");
const CreateInvoice = () => import("@/components/dialog/createDialogs/CreateInvoice");



export default {
  name: 'CustomerDetail',
  components: {
    HeadlineDetailView,
    KeyValueBlock,
    Message,
    CustomFieldBlock,
    CreateWedding,
    VirtualScrollInvoice,
    Tab,
    CreateInvoice
  },
  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('customer/getCustomer', {
      uid: this.user.id,
      customerId: this.$route.params.id
    }).then(() => {
      this.loaded = true
      this.$store.dispatch('wedding/getWeddingsForCustomer', {
        uid: this.user.id,
        customerId: this.$route.params.id,
        customer: {
          firstname: this.customer.firstname,
          partnerFirstname: this.customer.partnerFirstname
        }
      }).then(() => {
        this.loaded = true
      }).catch((err) => {
        this.message = error(err)
      })
      this.$store.dispatch('invoice/getInvoicesForCustomer', {
        customerId:  this.$route.params.id
      }).catch((err) => {
        this.message = error(err)
      })
      this.$store.dispatch('mail/getMailsForCustomer', {
        customer:  {
          email: this.customer.email
        }
      }).then(()=>{
        this.inbox.sort(function(a,b){
          return new Date(b.date) - new Date(a.date);
        });
      }).catch((err) => {
        this.message = error(err)
      })

      this.$store.dispatch('settings/getStatusForSelection', {
        uid: this.user.id
      }).catch((err) => {
        this.message = error(err)
      })
    }).catch((err) => {
      this.message = error(err)
    })

  },
  computed: {
    ...mapGetters('auth', {
      user: 'user',
    }),
    ...mapGetters('customer', {
      customer: 'customer',
    }),
    ...mapGetters('wedding', {
      weddingsForCustomer: 'weddingsForCustomer',
    }),
    ...mapGetters('invoice', {
      invoices: 'invoices',
    }),
    ...mapGetters('settings', {
      customerStatusForSelection: 'customerStatusForSelection'
    }),
    ...mapGetters('mail', {
      inbox: 'inbox'
    })
  },
  data() {
    return {
      dialogCreateWedding: false,
      tabs: 0,
      loaded: false,
      dialog: false,
      message: msgObj(),
      wedding: {},
      dialogInvoice: false
    }
  },
  methods: {
    getCustomerStatusColor(name){
      for(let status of this.customerStatusForSelection){
        if(name === status.value){
          return status.color
        }
      }
    },
    hideDialog() {
      this.dialogCreateWedding = false
    },
    getStringDate(date) {
      return date2String(date)
    },
    openWedding(id) {
      this.$router.push('/weddings/wedding/' + id)
    },
    archiveCustomer(){
      this.$store.dispatch('customer/editField', {
        entity: "customers",
        uid: this.user.id,
        id: this.$route.params.id,
        newValue: true,
        row: 'isArchived'
      }).then(() => {
        this.$router.push('/kunden')
      })
    },
    unArchiveWedding() {
      this.$store.dispatch('customer/editField', {
        entity: "customers",
        uid: this.$route.params.id,
        id: this.$route.params.id,
        newValue: false,
        row: 'isArchived'
      }).then(() => {
        this.$router.push('/kunden')
      })
    },
    editEntry(payload) {
      this.$store.dispatch('customer/editField', {
        entity: "customers",
        uid: this.user.id,
        id: payload.id,
        newValue: payload.newValue,
        row: payload.row
      }).then(() => {
        this.$store.dispatch('customer/getCustomer', {customerId: payload.id, uid: this.user.id})
            .catch((err) => {
              this.message = error(err)
            })
      }).catch((err) => {
        this.message = error(err)
      })
    }
  }
}
</script>
<style>


</style>
